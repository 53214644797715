<script setup name="Header">
import {MainLayoutStore} from "./main-layout-store";
import {
  Bars3Icon,
} from '@heroicons/vue/24/outline'
import MenuSelect from "~/components/menu-select/MenuSelect";
import MenuSelectItem from "~/components/menu-select/MenuSelectItem";
import LangSelect from "~/components/lang/LangSelect";
import Link from "~/components/link/Link.vue";
import {UserStore} from "~/store/user-store";
import {LangStore} from "~/common-store/lang-store";
import LangText from "~/components/lang/LangText";
import {ImStore} from "~/common-store/im-store";
import {LoadingOutlined,BellOutlined} from "@ant-design/icons-vue";
import Avatar from "~/components/avatar/Avatar";
import routeUtil from "~/utils/route";
import {StationLetterStore} from "~/pages/station-letter/station-letter-store";
import {Badge} from "ant-design-vue";

const mainLayoutStore = MainLayoutStore();
const imStore = ImStore();

const userStore = UserStore();
const userInfo = await userStore.userInfo();
const langStore = LangStore()
const stationLetterStore = StationLetterStore()
const merchCreateChatRoom = async () => {
  const r = routeUtil.getRouter()
  if (r.path === '/im/page') {
    return
  }
  await imStore.merchRegister();
  await imStore.merchCreateChatRoom();
}
</script>

<template>
  <div
      class="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
    <button type="button" class="-m-2.5 p-2.5 text-gray-700 lg:hidden" @click="mainLayoutStore.sidebarOpen = true">
      <span class="sr-only">Open sidebar</span>
      <Bars3Icon class="h-6 w-6" aria-hidden="true"/>
    </button>

    <!-- Separator -->
    <div class="h-6 hide w-px bg-gray-900/10 lg:hidden" aria-hidden="true"/>

    <div class="flex flex-1 justify-end gap-x-4 self-stretch lg:gap-x-6">
      <div class="flex items-center gap-x-4 lg:gap-x-6">
        <Link
            to="/station-letter/page"
        >
          <BellOutlined
              :style="{fontSize:'20px'}"
              class="cursor-pointer"
              v-if="stationLetterStore.unreadStationLetterList.length === 0"
          />
          <Badge
              v-else
              :count="stationLetterStore.unreadStationLetterList.length"
          >
            <BellOutlined
                :style="{fontSize:'20px'}"
                class="cursor-pointer"
            />
          </Badge>
        </Link>
        <LoadingOutlined v-if="imStore.merchCreateChatRoomIng || imStore.merchRegisterIng"/>
        <Avatar
            v-else
            src="/img/service-icon-default.png"
            size="20"
            class="cursor-pointer"
            @click="merchCreateChatRoom"
        />

        <!-- Separator -->
        <div class="lg:block lg:h-6 lg:w-px lg:bg-gray-900/10" aria-hidden="true"/>

        <LangSelect/>

        <div class="lg:block lg:h-6 lg:w-px lg:bg-gray-900/10" aria-hidden="true"/>

        <MenuSelect>
          <template #label>
            {{ userInfo.MerchUsername }}
          </template>
          <MenuSelectItem>
            <div @click="userStore.logout">
              <LangText msg-key="hZQ0otk5ke"/>
            </div>
          </MenuSelectItem>
          <MenuSelectItem>
            <Link to="/user-center/page">
              <lang-text msg-key="5fg66b9Z9o"/>
            </Link>
          </MenuSelectItem>
        </MenuSelect>
      </div>
    </div>
  </div>

</template>
